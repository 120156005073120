<template>
    <div class="LookMaterialsDetail">
        <el-page-header @back="$router.go(-1)" content="我在找料帖子"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item>我在找料</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="postInfo">
            <div class="f-c-b">
                <div class="title">
                    {{ postInfo.postHead }}
                </div>
                <div class="f-c">
                    <div class="cornermark ml-5" v-for="(item, index) of postInfo.productTypeList" :key="index">{{ item
                        }}</div>
                </div>
            </div>

            <div class="f-c-b">
                <div class="f-c subtitle">
                    <div class="subtitle"><i class="el-icon-user col-99 mr-5 fs-26"></i>联系人:{{ postInfo.contact }}</div>
                    <div class="subtitle ml-20"><i class="el-icon-user col-99 mr-5 fs-26"></i>联系电话 :{{
            postInfo.contactValue }}</div>

                </div>
                <div class="f-c subtitle">
                    <img class="img" src="@/assets/Community/IndustryTrends/date.png" alt="">
                    <div class="subtitle"> {{ postInfo.createDate }} </div>
                    <div class="ml-10">来自：{{ postInfo.province }}</div>
                </div>
            </div>
            <div class="postContent">
                <div v-html="postInfo.postContent" style="line-height: 28px;">
                </div>
                <div class="imgList f-w-b">
                    <div class="item mb-30" v-for="(item, index) in postInfo.imgUrlList" :key="index">
                        <el-image class="img" :src="item" :preview-src-list="[item]"></el-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="f-c-c" v-if="postInfo.postUserId == userId">
            <div class="f-c-c Btn mr-40" @click="removeForumBuy()">删除</div>
        </div>
    </div>
</template>

<script>

import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';
import { UnitAccount } from "@/utils/attributeValue1Options";
export default {
    name: 'LookMaterialsDetail',
    mixins: [PaginationRetention1],
    components: {
        // CommentItems: () => import("../components/CommentItems.vue"),
        // SecondaryComment: () => import("../components/SecondaryComment.vue")
    },
    data() {
        return {
            userId:'',
            postInfo: {},
            postId: '',
            commentValue: '',
            commentList: [],
            ShowSecondaryCommentsDialog: false,//显示二级评论盒子
            SecondaryParams: {},
        }
    },
    created() {
        let postId = JSON.parse(this.$UrlEncode.decode(this.$route.query.key)).postId;
        this.postId = postId;
        this.forumBuySearchListDetails(postId);
        this.userId = this.$store.state.userId
    },
    methods: {
        forumBuySearchListDetails(postId) {
            let that = this;
            that.$http.forumBuySearchListDetails({
                postId,
                postUserId: parseInt(that.$store.state.userId)
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data,
                        { postContent } = res.data,
                        province,
                        productTypeList = [],
                        arr;
                    records.postContent = postContent.replace(/\n/g, "<br/>");
                    records.postContent = records.postContent.replace(/ /g, "&nbsp;");
                    province = records.postLocation.split('*');
                    records.province = province[0];
                    // records.productType
                    arr = records.productType.split(',');
                    arr.forEach(item => {
                        productTypeList.push(UnitAccount.getproductType(item));
                    });
                    records.productTypeList = productTypeList;
                    that.postInfo = records;
                }
            })
        },
        removeForumBuy() {
            let that = this,
            { postId } = that;
            console.log(that.postId);
            that.$confirm('确定删除？', '提示').then(() => {
                that.$http.removeForumBuy({
                    postId,
                    postUserId: parseInt(that.$store.state.userId)
                }).then(res => {
                    that.$common.message(res.msg, "success");
                    that.$router.go(-1);
                })
            }).catch(() => { })
        }
    },
}

</script>
<style lang='less' scoped>
.LookMaterialsDetail {
    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .postInfo {
        // margin-top: 60px;
        margin-left: 1px;
        margin-right: 1px;
        border-radius: 10px;
        padding: 40px 68px;

        .title {
            font-weight: 500;
            font-size: 28px;
            color: #000000;
            margin-bottom: 30px;
        }

        .cornermark {
            border-radius: 5px;
            background: #E6F1FE;
            text-align: center;
            line-height: 16px;
            font-size: 16px;
            color: #0573F2;
            padding: 5px;
        }

        .subtitle {
            font-weight: 400;
            font-size: 14px;
            color: #666666;

            .img {
                margin-right: 5px;
            }
        }

        .postContent {
            margin-top: 40px;
            font-weight: 400;
            font-size: 16px;
            color: #333333;

            .imgList {
                margin-top: 20px;
                .item {

                    .img {
                        width: 542px;
                        height: 344px;
                        border-radius: 5px;
                    }
                }
            }
        }


    }

    .Btn {
        width: 160px;
        height: 50px;
        border-radius: 10px;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        color: #FFF;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        cursor: pointer;
    }

}
</style>